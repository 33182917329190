<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>
          <h1>Perfil</h1>
        </div>
        <!-- boxapp body -->
        <div class="box-main">
          <div class="section">
            <!-- section head -->
            <div class="head mt-0">
              <h4>Dados Cadastrais</h4>
            </div>
            <!-- conteudo -->
            <div class="form-grid">
              <v-layout row wrap>
                <v-flex
                  class="upload-box-profile-father"
                  style="width: 300px !important"
                  shrink
                  pr-3
                >
                  <FileImageUpload
                    :maxWidth="1000"
                    :maxHeight="1000"
                    :quality="0.7"
                    :file="company.file"
                    unselectedText="Logo da Empresa"
                  />
                  <!-- <div
                    class="upload-image bg-contain"
                    style="width:300px"
                    id="preview"
                    ref="preview"
                  >
                    <label for="upload-institution">
                      <i class="fas fa-camera-alt"></i>
                    </label>
                    <label
                      for="upload-institution"
                      class="do-upload"
                      v-show="company.file.path == ''"
                    >
                      <i class="fal fa-cloud-upload"></i>Logo da Empresa
                    </label>
                    <input
                      type="file"
                      id="upload-institution"
                      accept="image/*"
                      @change="fileChanged($event, true)"
                      ref="upload_institution"
                    />
                  </div>-->
                </v-flex>
                <v-flex form-grid xs12 md12 lg8 xl9>
                  <v-layout row wrap mw-total>
                    <v-flex xs12>
                      <!-- AUC only -->
                      <a
                        href="javascript:;"
                        class="text-btn"
                        style="float:right"
                        @click="openModalNewGroup"
                      >+ Novo Grupo</a>
                      <Modal v-model="showModalNewGroup" modalTitle="Novo Grupo">
                        <v-layout display-flex column pa-4>
                          <InputText
                            class="flex"
                            ref="groupInput"
                            v-model="companyGroup.name"
                            type="text"
                            place-holder="Nome do grupo"
                            textLabel="Nome do grupo"
                            invalidInputMessage="Preencha o nome do grupo"
                            :required="false"
                          />
                          <div class="footer-modal-atividades">
                            <v-btn
                              flat
                              round
                              color="white"
                              class="btn btn-default px-5"
                              large
                              @click="showModalNewGroup = false"
                            >Cancelar</v-btn>
                            <v-btn
                              flat
                              round
                              color="white"
                              class="btn btn-primary px-5"
                              large
                              @click="saveCompanyGroup(companyGroup)"
                            >Salvar</v-btn>
                          </div>
                        </v-layout>
                      </Modal>
                      <!--######-->
                      <InputSelect
                        ref="companyGroupListInput"
                        textLabel="Nome do grupo"
                        valueAttribute="id"
                        textAttribute="name"
                        :items="companyGroupList"
                        invalidInputMessage="Preencha o nome do grupo"
                        v-model="company.companyGroupId"
                      ></InputSelect>
                    </v-flex>
                    <v-flex xs12 sm6>
                      <InputText
                        ref="nameInput"
                        v-model="company.entity.name"
                        type="text"
                        place-holder="Nome"
                        textLabel="Nome da Empresa"
                        invalidInputMessage="Preencha o nome da Empresa"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <InputMoney
                      :precision="0"
                      ref="employeeQuantityInput"
                      v-model="company.employeeQuantity"
                      type="number"
                      prefix=""
                      place-holder="Número de funcionários"
                      textLabel="Número de funcionários"
                      invalidInputMessage="Preencha o número de funcionários"
                      :required="!hasPermission([permissions.CRUDCompany])"
                    />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <InputText
                        ref="socialReasonInput"
                        v-model="company.entity.socialReason"
                        type="text"
                        place-holder="Razão Social"
                        textLabel="Razão Social"
                        invalidInputMessage="Preencha a razão social da Empresa"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <InputText
                        ref="cnpjInput"
                        v-model="company.entity.cnpj"
                        type="tel"
                        @input="verifyCNPJ"
                        place-holder="CNPJ"
                        textLabel="CNPJ"
                        invalidInputMessage="Preencha o CNPJ da Empresa"
                        mask="##.###.###/####-##"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <BorderSeparation />
              <v-layout row>
                <v-flex xs12 md6>
                  <InputText
                    ref="postalCodeInput"
                    v-model="company.entity.address.postalCode"
                    type="tel"
                    textLabel="CEP"
                    place-holder="CEP"
                    :showLoader="loaderInput"
                    invalidInputMessage="Preencha o CEP da instituição"
                    :required="!hasPermission([permissions.CRUDCompany])"
                    mask="#####-###"
                    @input="searchCEP()"
                  />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <InputText
                    ref="streetInput"
                    v-model="company.entity.address.street"
                    type="text"
                    textLabel="Endereço"
                    place-holder="Endereço"
                    invalidInputMessage="Preencha o endereço da instituição"
                    :required="!hasPermission([permissions.CRUDCompany])"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <InputText
                    ref="numberInput"
                    v-model="company.entity.address.number"
                    type="number"
                    textLabel="Número"
                    place-holder="Número"
                    :required="false"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <InputText
                    ref="complementInput"
                    v-model="company.entity.address.complement"
                    type="text"
                    textLabel="Complemento"
                    place-holder="Complemento"
                    :required="false"
                  />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <InputText
                    ref="neighborhoodInput"
                    v-model="company.entity.address.neighborhood"
                    type="text"
                    textLabel="Bairro"
                    place-holder="Bairro"
                    invalidInputMessage="Preencha o bairro da instituição"
                    :required="!hasPermission([permissions.CRUDCompany])"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <InputText
                    ref="cityInput"
                    v-model="company.entity.address.city"
                    type="text"
                    textLabel="Cidade"
                    place-holder="Cidade"
                    invalidInputMessage="Preencha a cidade da empresa"
                    :disabled="true"
                    :required="!hasPermission([permissions.CRUDCompany])"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <InputSelect
                    ref="stateInput"
                    :items="stateList"
                    v-model="company.entity.address.state"
                    textLabel="Estado"
                    valueAttribute="name"
                    textAttribute="name"
                    invalidInputMessage="Preencha o estado da empresa"
                    :disabled="true"
                    :required="!hasPermission([permissions.CRUDCompany])"
                  ></InputSelect>
                </v-flex>
              </v-layout>
              <template v-if="showDocumentsBlock">
                <BorderSeparation />
                <div class="head mt-0">
                  <h4>Documentos</h4>
                </div>
                <div class="box-upload">
                  <div class="name-doc-institution">
                    <h4>Documento</h4>
                  </div>
                  <div class="archive-doc-institution">
                    <span>Enviar arquivo</span>
                    <div class="upload-bar-gallery">
                      <label :for="'upload'">
                        <i class="fal fa-cloud-upload"></i>
                      </label>
                      <input
                        type="file"
                        :id="'upload'"
                        accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .html, .xlsx, .xls, .csv"
                        @change="fileChanged($event)"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="box-upload"
                  v-for="(item, index) in company.fileCompanyList"
                  :key="item.file.name"
                >
                  <v-layout row wrap>
                    <v-flex xs12 md7>
                      <InputText type="text" place-holder="Nome do arquivo" v-model="item.name" />
                    </v-flex>
                    <v-flex xs12 md5 display-flex justify-end align-center>
                      <label class="mr-3">{{item.file.name}}</label>
                      <a @click="openRemoveDocument(index)">
                        <Icon class="fal fa-trash" size="16" colorFont="#cfd2dd" />
                      </a>
                    </v-flex>
                  </v-layout>
                  <!-- <input type="text" v-model="item.name"/> -->
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="box-help-spacer"></div>
      <HelpBox :text="helpBoxText" />
    </v-layout>
    <AucAlert
      ref="removeDocument"
      title="Você tem certeza que deseja excluir esse documento?"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="removeDocument(selectedDocument)"
    />
    <div class="footer-step">
      <v-btn
        flat
        round
        color="white"
        class="btn-primary flex-inherit px-5 ml-auto"
        large
        @click="save"
      >Concluir</v-btn>
    </div>
  </div>
</template>

<script type="plain/text">
import Modal from "@/components/Modal.vue";
import AucAlert from "@/components/AucAlert.vue";
import FooterStep from "@/components/FooterStep.vue";
import HelpBox from "@/components/HelpBox.vue";
import Company from "@/scripts/models/company.model.js";
import CompanyGroup from "@/scripts/models/companyGroup.model.js";
import CompanyService from "@/scripts/services/company.service.js";
import FileService from "@/scripts/services/file.service.js";
import CompanyGroupService from "@/scripts/services/companyGroup.service.js";
import Entity from "@/scripts/models/entity.model.js";
import EntityService from "@/scripts/services/entity.service.js";
import File from "@/scripts/models/file.model.js";
import Address from "@/scripts/models/address.model.js";
import FileCompany from "@/scripts/models/fileCompany.model.js";
import LocationService from "@/scripts/services/location.service.js";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import Permissions from "@/scripts/models/enums/permissions.enum";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import { mapGetters } from "vuex";
import store from "@/store.js";
import ImageCompressor from "image-compressor.js";
import CommonHelper from "@/scripts/helpers/common.helper";
import {
  EntityType,
  EntityTypeList
} from "@/scripts/models/enums/entityType.enum";

export default {
  extends: ValidatableComponent,
  components: {
    HelpBox,
    Modal,
    AucAlert
  },
  props: ["pageOptions"],
  computed: {
    ...mapGetters(["hasPermission"])
  },
  data() {
    return {
      permissions: Permissions,
      companyService: new CompanyService(),
      company: new Company(),
      companyGroupService: new CompanyGroupService(),
      fileService: new FileService(),
      companyGroup: new CompanyGroup(),
      locationService: new LocationService(),
      entityService: new EntityService(),
      cnpjUnique: true,
      fileCompany: null,
      companyGroupList: [],
      stateList: [],
      helpBoxText: HelpBoxText.CompanyProfile,
      showModalNewGroup: false,
      showDocumentsBlock: false,
      selectedDocument: null,
      loaderInput: false,
      cepValidation: true,
      breadcrumbLevels: [
        { text: "Início" },
        { text: "Empresa" },
        { text: "Perfil" }
      ]
    };
  },
  beforeRouteEnter: (to, from, next) => {
    let hasPermission = false;
    if (to.query.companyId && to.query.companyId != 0) {
      if (
        store.getters.hasPermission(
          [Permissions.UpdateCompany],
          to.query.entityId
        ) ||
        store.getters.hasPermission([Permissions.CRUDCompany])
      ) {
        hasPermission = true;
      } else {
        hasPermission = false;
      }
    } else {
      if (store.getters.hasPermission([Permissions.CRUDCompany])) {
        hasPermission = true;
      } else {
        hasPermission = false;
      }
    }
    if (hasPermission) next();
    else {
      store.commit(
        "SET_SNACKBAR_MESSAGE",
        "Você não tem permissão para acessar essa página."
      );
      next(false);
    }
  },
  created() {
    this.$emit("changeLevels", this.breadcrumbLevels);
    this.validationMethod = this.validate;
    if (this.$route.query != null && this.$route.query.companyId != null) {
      this.companyService.find(
        this.findCompanyCallback,
        this.$route.query.companyId
      );
    }

    this.companyGroupService.listAllCompanyGroup(
      this.listAllCompanyGroupCallback
    );
    this.locationService.listAllState(this.listAllStateCallback);
    this.pageOptions.changeClass("footer");
  },
  methods: {
    verifyCNPJ() {
      if (this.company.entity.cnpj.length < 14) return false;
      this.entityService
        .findByCNPJAndEntityType(this.company.entity.cnpj, EntityType.Company)
        .then(
          function(data) {
            if (data != null && this.company.entity.id != data.id) {
              this.$store.commit(
                "SET_SNACKBAR_MESSAGE",
                "Já existe uma Empresa cadastrada com esse CNPJ"
              );
              this.cnpjUnique = false;
            } else {
              this.cnpjUnique = true;
            }
          }.bind(this)
        );
    },
    searchCEP() {
      if (
        this.company.entity.address.postalCode == "" ||
        this.company.entity.address.postalCode.length < 8
      )
        return false;
      this.loaderInput = true;
      this.locationService
        .searchCEP(this.company.entity.address.postalCode)
        .then(data => {this.searchCEPCallback(data)});
    },
    searchCEPCallback(obj) {
      if (!obj.erro) {
        if(obj.street) this.company.entity.address.street = obj.street;
        if(obj.neighborhood) this.company.entity.address.neighborhood = obj.neighborhood;
        this.company.entity.address.city = obj.city;
        this.company.entity.address.state = obj.state;
        this.cepValidation = true;
      } else {
        this.company.entity.address.street = "";
        this.company.entity.address.neighborhood = "";
        this.company.entity.address.city = "";
        this.company.entity.address.state = "";
        this.cepValidation = false;
      }
      this.loaderInput = false;
    },
    findCompanyCallback(data) {
      this.company = data;
      if (this.$route.query != null && this.$route.query.companyId != "") {
        this.companyService.listFileCompany(
          this.listFileCompanyCallback,
          this.$route.query.companyId
        );
      }
      if (this.company.entity == null) this.company.entity = new Entity();
      if (this.company.entity.address == null)
        this.company.entity.address = new Address();
      if (this.company.file == null) this.company.file = new File();
      // else {
      //   this.fileService.render(this.company.file.id).then(this.renderCallback);
      // }
    },
    listFileCompanyCallback(data) {
      this.company.fileCompanyList = data;
    },
    renderCallback(data) {
      // this.$refs.preview.style.backgroundImage = "url(" + data + ")";
    },
    openRemoveDocument(index) {
      this.selectedDocument = index;
      this.$refs.removeDocument.open();
    },
    removeDocument(index) {
      this.company.fileCompanyList.splice(index, 1);
    },
    saveCompanyGroup(companyGroup) {
      if (this.companyGroup.name != "") {
        this.companyGroupService.save(
          this.saveCompanyGroupCallback,
          companyGroup
        );
        this.showModalNewGroup = false;
      } else {
        this.$refs.groupInput.showValidationMessage = true;
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Você não preencheu o nome do grupo"
        );
      }
    },
    saveCompanyGroupCallback(data) {
      this.companyGroupService.listAllCompanyGroup(
        this.listAllCompanyGroupCallback
      );
    },
    openModalNewGroup() {
      this.companyGroup = new CompanyGroup();
      this.showModalNewGroup = true;
    },
    listAllStateCallback(data) {
      this.stateList = data;
    },
    listAllCompanyGroupCallback(data) {
      this.companyGroupList = data;
    },
    fileChanged(event) {
      var file = event.target.files[0];
      this.fileCompany = new FileCompany();
      this.fileCompany.file.name = file.name;
      let reader = new FileReader();
      reader.onload = function() {
        this.fileCompany.file.path = reader.result;
        this.company.fileCompanyList.push(this.fileCompany);
      }.bind(this);
      reader.readAsDataURL(file);
    },
    save() {
      if (this.isValid()) {
        this.companyService.save(this.saveCallback, this.company);
      }
    },
    validate(performMethods) {
      if (!this.hasPermission([Permissions.CRUDCompany])) {
        if (
          this.company.file == null ||
          this.company.file.path == null ||
          this.company.file.path == ""
        ) {
          this.$store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Escolha uma imagem para a empresa"
          );
          return false;
        }
        if (!this.cepValidation) {
          this.$store.commit("SET_SNACKBAR_MESSAGE", "CEP Inválido");
          return false;
        }
      }
      if (this.company.entity.address.postalCode != "") {
        if (!this.cepValidation) {
          this.$store.commit("SET_SNACKBAR_MESSAGE", "CEP Inválido");
          return false;
        }
      }
      let valid = this.validateComponents(performMethods);

      if (
        this.company.entity.cnpj != "" &&
        !CommonHelper.validateCNPJ(this.company.entity.cnpj)
      ) {
        this.$store.commit("SET_SNACKBAR_MESSAGE", "CNPJ inválido");
        return false;
      } else if (!this.cnpjUnique) {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Já existe uma Empresa cadastrada com esse CNPJ"
        );
        return false;
      }

      if (!valid) return false;
      return true;
    },
    saveCallback(data) {
      this.$router.push({
        name: Routes.app.User,
        query: { entityId: data.entity.id }
      });
    }
  }
};
</script>
