import File from "@/scripts/models/file.model";
export default class FileCompany{

    constructor(){

        this.id;
        this.file = new File();
        this.name = null;

    }
}