import BaseCrudService from "./baseCrud.service";
import ApiService from "./api.service";

export default class CompanyGroupService extends BaseCrudService {

	constructor() {
		super('CompanyGroup');
	}

	save(callback, group){
		this.postRequest('Save', group, true, true).then(callback);
	}

	listAllCompanyGroup(callback) {
		this.getRequest('ListAll', null, false, true).then(callback);
	}

	loadCompaniesInformation(){
		return this.getRequest('LoadCompaniesInformation');
	}
}